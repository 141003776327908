import "./ImageViewByID.css";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import ImageView from "../ImageView/ImageView";

function ImageViewByID() {
  const { uuid } = useParams();
  const config = JSON.parse(sessionStorage.getItem("config"));

  return (
    <div style={{ backgroundColor: "black", height: "100vh" }}>
      <ImageView
        uuid={uuid}
        style={{ width: "100%", height: "100%" }}></ImageView>

      {/* <div
        style={{
          position: "fixed",
          bottom: "0",
          width: "100%",
          textAlign: "center",
          padding: "10px",
        }}>
        <a href={`${config.frontendURL}/gallery`} style={{ color: "white" }}>
          כניסה לגלריה
        </a>
      </div> */}
    </div>
  );
}

export default ImageViewByID;
