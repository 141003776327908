// import React, { useEffect, useState, useRef } from "react";
// import "./ImageDataProperty.css";
// import { Tag, Input, theme, message } from "antd";
// import { PlusOutlined } from "@ant-design/icons";
// import { useSelector, useDispatch } from "react-redux";
// import { updateImage } from "../../features/imagesSlice";

// function ImageDataProperty({
//   property,
//   value,
//   tags,
//   icon,
//   isAiTags = false,
//   dataTestID,
// }) {
//   const language = useSelector((state) => state.language.language);
//   const translations = JSON.parse(sessionStorage.getItem("translations"));
//   const config = JSON.parse(sessionStorage.getItem("config"));

//   const dispatch = useDispatch();
//   const user = useSelector((state) => state.user);
//   const image = useSelector((state) => state.image.image);
//   const [tagsApprover, setTagsApprover] = useState(false);

//   const [addedTags, setAddedTags] = useState([]);
//   const { token } = theme.useToken();
//   const [editInputIndex, setEditInputIndex] = useState(-1);
//   const [editInputValue, setEditInputValue] = useState("");
//   const editInputRef = useRef(null);
//   const [inputValue, setInputValue] = useState("");
//   const [inputVisible, setInputVisible] = useState(false);
//   const inputRef = useRef(null);

//   const handleEditInputConfirm = () => {
//     const newTags = [...addedTags];
//     newTags[editInputIndex] = editInputValue;
//     setAddedTags(newTags);
//     setEditInputIndex(-1);
//     setInputValue("");
//   };

//   const handleClose = (removedTag) => {
//     const newTags = addedTags.filter((tag) => tag !== removedTag);
//     setAddedTags(newTags);
//   };

//   useEffect(() => {
//     if (inputVisible) {
//       inputRef.current?.focus();
//     }
//   }, [inputVisible]);

//   useEffect(() => {
//     editInputRef.current?.focus();
//   }, [inputValue]);

//   const handleInputChange = (e) => {
//     setInputValue(e.target.value);
//   };

//   const handleInputConfirm = () => {
//     if (inputValue === "") {
//       return;
//     }

//     if (inputValue && addedTags.indexOf(inputValue) === -1) {
//       setAddedTags([...addedTags, inputValue]);
//     }

//     let updatedTags = [...tags];

//     if (!updatedTags.includes(inputValue)) {
//       updatedTags.push(inputValue);
//     }

//     const updatedImage = {
//       ...image,
//       tags: { tags: updatedTags },
//     };

//     dispatch(updateImage(updatedImage));

//     const body = {
//       target: isAiTags ? "aiTags" : "tags",
//       uuid: image.uuid,
//       tags: JSON.stringify({ tags: updatedTags }),
//     };

//     fetch(`${process.env.REACT_APP_BACKEND_API_URL}/api/updateMediaMetaData`, {
//       method: "POST",
//       headers: {
//         "Content-Type": "application/json",
//       },
//       body: JSON.stringify(body),
//     });

//     setInputVisible(false);
//     setInputValue("");
//   };

//   const showInput = () => {
//     setInputVisible(true);
//   };

//   useEffect(() => {
//     if (user.roles.includes("approver") || user.roles.includes("admin")) {
//       setTagsApprover(true);
//     } else {
//       setTagsApprover(false);
//     }
//   }, [user]);

//   return (
//     <div className="image-data-property" data-testid={dataTestID}>
//       <div className="property">
//         <h3 style={{ color: config.secondaryColor, fontWeight: "600" }}>
//           {icon}
//           {property}
//         </h3>
//       </div>
//       <div className="value ellipsis">
//         <p
//           style={{
//             color: config.secondaryColor,
//           }}
//           className={`text-[${config.secondaryColor}]`}>
//           {value}
//         </p>
//       </div>
//       {!tagsApprover && (
//         <div className="tags">
//           {tags &&
//             tags.map((tag, index) => (
//               <div key={index}>
//                 <Tag
//                   onClick={() => {
//                     const searchTerm = tag;
//                     const currentURL = window.location.href.split("gallery")[0];
//                     window.open(
//                       `${currentURL}gallery?search=${searchTerm}`,
//                       "_self"
//                     );
//                   }}>
//                   {tag}
//                 </Tag>
//               </div>
//             ))}
//         </div>
//       )}
//       {tagsApprover && (
//         <div className="tags-approver">
//           {tags &&
//             tags.map((tag, index) => (
//               <div key={index}>
//                 <Tag
//                   className="tag-button"
//                   style={{
//                     display: "flex",
//                     alignItems: "center",
//                     justifyContent: "center",
//                     gap: "5px",
//                   }}>
//                   {tag}
//                   <span
//                     className="x-mark"
//                     onClick={() => {
//                       if (tags.includes(tag)) {
//                         tags = tags.filter((item) => item !== tag);
//                       }

//                       if (isAiTags) {
//                         dispatch(
//                           updateImage({
//                             ...image,
//                             aiTags: JSON.stringify({ tags: tags }),
//                           })
//                         );
//                       } else {
//                         dispatch(
//                           updateImage({
//                             ...image,
//                             tags: { tags: tags },
//                           })
//                         );
//                       }

//                       const body = {
//                         target: isAiTags ? "aiTags" : "tags",
//                         uuid: image.uuid,
//                         tags: JSON.stringify({ tags: tags }),
//                       };

//                       fetch(
//                         `${process.env.REACT_APP_BACKEND_API_URL}/api/updateMediaMetaData`,
//                         {
//                           method: "POST",
//                           headers: {
//                             "Content-Type": "application/json",
//                           },
//                           body: JSON.stringify(body),
//                         }
//                       );
//                     }}>
//                     x
//                   </span>
//                 </Tag>
//               </div>
//             ))}
//           {tags && property !== "AI Tags" && (
//             <Input
//               placeholder={
//                 translations.find(
//                   (translation) => translation.id === "upload.add_tag"
//                 )?.[language]
//               }
//               ref={inputRef}
//               type="text"
//               size="small"
//               style={{
//                 width: "90px",
//                 height: "30px",
//                 verticalAlign: "top",
//               }}
//               value={inputValue}
//               onChange={handleInputChange}
//               onBlur={handleInputConfirm}
//               onPressEnter={handleInputConfirm}
//               maxLength={100}
//             />
//           )}
//         </div>
//       )}
//     </div>
//   );
// }

// export default ImageDataProperty;

import React, { useEffect, useState, useRef } from "react";
import "./ImageDataProperty.css";
import { Tag, Input, theme } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { updateImage } from "../../features/imagesSlice";

function ImageDataProperty({
  property,
  value,
  tags,
  icon,
  isAiTags = false,
  dataTestID,
}) {
  const language = useSelector((state) => state.language.language);
  const translations = JSON.parse(sessionStorage.getItem("translations"));
  const config = JSON.parse(sessionStorage.getItem("config"));

  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const image = useSelector((state) => state.image.image);
  const [tagsApprover, setTagsApprover] = useState(false);

  const [addedTags, setAddedTags] = useState([]);
  const { token } = theme.useToken();
  const [editInputIndex, setEditInputIndex] = useState(-1);
  const [editInputValue, setEditInputValue] = useState("");
  const editInputRef = useRef(null);
  const [inputValue, setInputValue] = useState("");
  const [inputVisible, setInputVisible] = useState(false);
  const inputRef = useRef(null);

  const handleEditInputConfirm = () => {
    const newTags = [...addedTags];
    newTags[editInputIndex] = editInputValue;
    setAddedTags(newTags);
    setEditInputIndex(-1);
    setInputValue("");
  };

  const handleClose = (removedTag) => {
    const newTags = addedTags.filter((tag) => tag !== removedTag);
    setAddedTags(newTags);
  };

  useEffect(() => {
    if (inputVisible) {
      inputRef.current?.focus();
    }
  }, [inputVisible]);

  useEffect(() => {
    editInputRef.current?.focus();
  }, [inputValue]);

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleInputConfirm = () => {
    if (inputValue === "") {
      return;
    }

    if (inputValue && addedTags.indexOf(inputValue) === -1) {
      setAddedTags([...addedTags, inputValue]);
    }

    let updatedTags = [...tags];

    if (!updatedTags.includes(inputValue)) {
      updatedTags.push(inputValue);
    }

    const updatedImage = {
      ...image,
      [isAiTags ? "aiTags" : "tags"]: { tags: updatedTags },
    };

    dispatch(updateImage(updatedImage));

    const body = {
      target: isAiTags ? "aiTags" : "tags",
      uuid: image.uuid,
      tags: JSON.stringify({ tags: updatedTags }),
    };

    fetch(`${process.env.REACT_APP_BACKEND_API_URL}/api/updateMediaMetaData`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });

    setInputVisible(false);
    setInputValue("");
  };

  const showInput = () => {
    setInputVisible(true);
  };

  useEffect(() => {
    if (user.roles.includes("approver") || user.roles.includes("admin")) {
      setTagsApprover(true);
    } else {
      setTagsApprover(false);
    }
  }, [user]);

  return (
    <div className="image-data-property" data-testid={dataTestID}>
      <div className="property">
        <h3 style={{ color: config.secondaryColor, fontWeight: "600" }}>
          {icon}
          {property}
        </h3>
      </div>
      <div className="value ellipsis">
        <p
          style={{
            color: config.secondaryColor,
          }}
          className={`text-[${config.secondaryColor}]`}>
          {value}
        </p>
      </div>
      {!tagsApprover && (
        <div className="tags">
          {tags &&
            tags.map((tag, index) => (
              <div key={index}>
                <Tag
                  onClick={() => {
                    const searchTerm = tag;
                    const currentURL = window.location.href.split("gallery")[0];
                    window.open(
                      `${currentURL}gallery?search=${searchTerm}`,
                      "_self"
                    );
                  }}>
                  {tag}
                </Tag>
              </div>
            ))}
        </div>
      )}
      {tagsApprover && (
        <div className="tags-approver">
          {tags &&
            tags.map((tag, index) => (
              <div key={index}>
                <Tag
                  className="tag-button"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "5px",
                  }}>
                  {tag}
                  <span
                    className="x-mark"
                    onClick={() => {
                      if (tags.includes(tag)) {
                        tags = tags.filter((item) => item !== tag);
                      }

                      const updatedImage = {
                        ...image,
                        [isAiTags ? "aiTags" : "tags"]: { tags: tags },
                      };

                      dispatch(updateImage(updatedImage));

                      const body = {
                        target: isAiTags ? "aiTags" : "tags",
                        uuid: image.uuid,
                        tags: JSON.stringify({ tags: tags }),
                      };

                      fetch(
                        `${process.env.REACT_APP_BACKEND_API_URL}/api/updateMediaMetaData`,
                        {
                          method: "POST",
                          headers: {
                            "Content-Type": "application/json",
                          },
                          body: JSON.stringify(body),
                        }
                      );
                    }}>
                    x
                  </span>
                </Tag>
              </div>
            ))}
          {tags && property !== "AI Tags" && (
            <Input
              placeholder={
                translations.find(
                  (translation) => translation.id === "upload.add_tag"
                )?.[language]
              }
              ref={inputRef}
              type="text"
              size="small"
              style={{
                width: "90px",
                height: "30px",
                verticalAlign: "top",
              }}
              value={inputValue}
              onChange={handleInputChange}
              onBlur={handleInputConfirm}
              onPressEnter={handleInputConfirm}
              maxLength={100}
            />
          )}
        </div>
      )}
    </div>
  );
}

export default ImageDataProperty;
