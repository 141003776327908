import React, { useState, useMemo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  signInWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithPopup,
} from "firebase/auth";
import { auth } from "../../firebase";
import jwt_decode from "jwt-decode";
import "./Login.css";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import { setRoles, setJWT, setUser } from "../../features/userSlice";

const translate = (translations, id, language) => {
  const translation = translations.find((t) => t.id === id);
  return translation ? translation[language] : id;
};

const getUserRole = async (jwt) => {
  try {
    const userRolesResponse = await fetch(
      process.env.REACT_APP_BACKEND_API_URL + "/api/postUserRole",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          jwt: jwt,
        }),
      }
    );

    if (!userRolesResponse.ok) {
      throw new Error(`HTTP error! status: ${userRolesResponse.status}`);
    }

    const userRoles = await userRolesResponse.text();

    return userRoles;
  } catch (error) {
    console.error("Error fetching user role:", error);
    throw error;
  }
};

function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const [showForgotPasswordMessage, setShowForgotPasswordMessage] =
    useState(false);

  const translations = JSON.parse(sessionStorage.getItem("translations")) || [];
  const language = useSelector((state) => state.language.language);
  const config = JSON.parse(sessionStorage.getItem("config")) || {};

  const translateMemoized = useMemo(
    () => (id) => translate(translations, id, language),
    [translations, language]
  );

  const onLogin = (e) => {
    e.preventDefault();
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        const userObject = jwt_decode(userCredential.user.accessToken);

        getUserRole(userCredential.user.accessToken).then((userRole) => {
          dispatch(setJWT(userCredential.user.accessToken));
          dispatch(setUser(userObject));
          dispatch(setRoles([userRole]));

          navigate("/");
        });
      })
      .catch((error) => {
        setError(error.message);
        console.error(error.code, error.message);
      });
  };

  const handleGoogleLogin = async () => {
    try {
      const provider = new GoogleAuthProvider();

      const userCredential = await signInWithPopup(auth, provider);
      const token = userCredential.user.accessToken;
      const userObject = jwt_decode(token);

      getUserRole(userCredential.user.accessToken).then((userRole) => {
        dispatch(setJWT(userCredential.user.accessToken));
        dispatch(setUser(userObject));
        dispatch(setRoles([userRole]));

        navigate("/");
      });
    } catch (err) {
      setError(err.message);
    }
  };

  const handleForgotPasswordClick = () => {
    setShowForgotPasswordMessage(true);
  };

  return (
    <div>
      <Header showSearch={false} showLinks={true} />

      <div className="login-container">
        <div
          className="login-background"
          style={{
            background: `url(${
              config.heroBackgroundImage || ""
            }) no-repeat center center`,
            backgroundSize: "cover",
          }}></div>
        <div className="login-overlay">
          <div className="login-center">
            <div className="login-box">
              <h1 className="title-hero">{translateMemoized("login.title")}</h1>

              {error && (
                <p className="error-message">
                  {translateMemoized("login.error")}
                </p>
              )}

              {showForgotPasswordMessage && (
                <p className="error-message">
                  {translateMemoized("login.forgot_password_message")}
                </p>
              )}

              <form onSubmit={onLogin}>
                <div>
                  <label
                    htmlFor="email"
                    className={`button-container ${
                      language === "english"
                        ? "button-container-row-reverse"
                        : ""
                    }`}>
                    {translateMemoized("login.email.label")}
                  </label>
                  <input
                    dir="ltr"
                    type="email"
                    id="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>

                <div>
                  <label
                    htmlFor="password"
                    className={`button-container ${
                      language === "english"
                        ? "button-container-row-reverse"
                        : ""
                    }`}>
                    {translateMemoized("login.password.label")}
                  </label>
                  <input
                    dir="ltr"
                    type="password"
                    id="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>

                <div
                  className={`button-container ${
                    language === "english" ? "button-container-row-reverse" : ""
                  }`}>
                  <button type="button" onClick={handleForgotPasswordClick}>
                    {translateMemoized("login.forgot_password")}
                  </button>
                  <button type="submit">
                    {translateMemoized("login.submit")}
                  </button>
                </div>
              </form>

              <div className="divider">
                <hr />
                <div className="divider-text">
                  {translateMemoized("login.or")}
                </div>
              </div>

              <button
                type="button"
                className="login-with-google-btn"
                onClick={handleGoogleLogin}>
                {translateMemoized("login.google")}
              </button>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Login;
