import "./App.css";
import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
  useParams,
} from "react-router-dom";
import CategoriesPage from "./pages/CategoriesPage/CategoriesPage";
import AlbumsPage from "./pages/AlbumsPage/AlbumsPage";
import Login from "./pages/Login/Login";
import GenericPage from "./components/GenericPage/GenericPage";
import Gallery from "./pages/Gallery/Gallery";
import Home from "./pages/Home/Home";
import { ConfigProvider } from "antd";
import ImageViewByID from "./components/ImageViewByID/ImageViewByID";
import { Spin } from "antd";
import UploadScreen from "./components/UploadScreen/UploadScreen";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import { useSelector } from "react-redux";
import HeroMobile from "./components/HeroMobile/HeroMobile";
import AlbumPage from "./pages/AlbumPage/AlbumPage";
import InfiniteGallery from "./components/InfiniteGallery/InfiniteGallery";

async function fetchData() {
  try {
    const response = await fetch(
      process.env.REACT_APP_BACKEND_API_URL + "/api/getTranslations"
    );
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.log("Error fetching data:", error);
    throw error; // rethrow the error to handle it further up the chain
  }
}

async function fetchDataConfig() {
  try {
    const response = await fetch(
      process.env.REACT_APP_BACKEND_API_URL + "/api/getConfig"
    );
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const data = await response.json();

    if (!data) {
      console.error("Config not found");
    } else {
      const configKeys = Object.keys(data);
      configKeys.forEach((key) => {
        if (data[key] === "true") {
          data[key] = true;
        }

        if (data[key] === "false") {
          data[key] = false;
        }
      });
    }

    return data;
  } catch (error) {
    console.log("Error fetching data:", error);
    throw error; // rethrow the error to handle it further up the chain
  }
}

function App() {
  // Protect from clickjacking
  useEffect(() => {
    if (window !== window.top) {
      window.top.location = window.location;
    }
  }, []);

  const [loading, setLoading] = useState(true);
  const [translations, setTranslations] = useState(null);
  const user = useSelector((state) => state.user);
  const direction = useSelector((state) => state.language.direction);
  const language = useSelector((state) => state.language.language);
  const image = useSelector((state) => state.image.image);
  const { album_name } = useParams();

  const envString = process.env.REACT_APP_ENV_TEST;

  if (envString) {
    // Split the string into lines
    const lines = envString.split("\n");

    // Create an object to store the environment variables
    const envVariables = {};

    // Iterate through each line
    lines.forEach((line) => {
      // Check if the line contains an environment variable definition
      const match = line.match(/^\s*([A-Za-z_][A-Za-z0-9_]*)\s*=\s*(.*)\s*$/);

      if (match) {
        const key = match[1];
        const value = match[2].replace(/["']/g, ""); // Remove quotes from the value

        // Store the environment variable in the object
        envVariables[key] = value;
      }
    });
  }

  useEffect(() => {
    Promise.all([fetchData(), fetchDataConfig()])
      .then(([translationsData, configData]) => {
        sessionStorage.setItem("translations", JSON.stringify(translationsData));
        sessionStorage.setItem("config", JSON.stringify(configData));
        setTranslations(translationsData);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error loading data:", error);
        setLoading(false);
      });
  }, []);

  const isReferrerYourWebsite = document.referrer.includes(
    window.location.hostname
  );

  function addAlbum() {
    // open the add album modal
  }

  if (loading) {
    return (
      <div
        className="loading-container"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}>
        <Spin color="#00FF00" size="large" />
      </div>
    );
  }

  if (user.roles.includes("photographer") && user.roles.length == 1) {
    return (
      <div style={{ direction: direction }}>
        <Header />
        <div style={{ paddingTop: "80px" }}>
          <center>
            <h2>
              {
                translations.find(
                  (translation) => translation.id === "upload.title"
                )?.[language]
              }
            </h2>
          </center>
          <div
            style={{
              width: "800px",
              marginLeft: "auto",
              marginRight: "auto",
              marginTop: "50px",
              textAlign: "center",
              paddingBottom: "100px",
            }}>
            <UploadScreen />
          </div>
          <Footer />
        </div>
      </div>
    );
  }

  return (
    // <Router>
    <div>
      <div className="unsupported-page">
        <div>
          <HeroMobile />
        </div>
      </div>
      <div className="app">
        <ConfigProvider direction="rtl">
          <div className="app_body">
            <Routes>
              <Route path="/" element={<Home />} />

              <Route path="/login" element={<Login />} />

              <Route
                path="/categories"
                element={<CategoriesPage subCategory={false} />}
              />
              <Route
                path="/categories/:sub_category"
                element={
                  <CategoriesPage
                    url={window.location.href}
                    subCategory={true}
                  />
                }
              />

              <Route path="/albums" element={<AlbumsPage />} />
              <Route path="/albums/:album" element={<AlbumPage></AlbumPage>} />
              <Route path="/albums/:album/:uuid" element={<ImageViewByID />} />

              <Route path="/gallery" element={<Gallery />} />
              <Route
                path="/gallery/:uuid"
                element={
                  isReferrerYourWebsite ? (
                    <ImageViewByID referrer={"gallery"} />
                  ) : (
                    <ImageViewByID />
                  )
                }
              />

              <Route
                path="/about"
                element={
                  <GenericPage
                    title={
                      translations.find(
                        (translation) => translation.id === "about_page_title"
                      )?.[language]
                    }
                    description={
                      translations.find(
                        (translation) =>
                          translation.id === "about_page_description"
                      )?.[language]
                    }
                  />
                }
              />
              <Route
                path="/terms"
                element={
                  <GenericPage
                    title={
                      translations.find(
                        (translation) => translation.id === "terms_page_title"
                      )?.[language]
                    }
                    description={
                      translations.find(
                        (translation) =>
                          translation.id === "terms_page_description"
                      )?.[language]
                    }
                  />
                }
              />
              <Route
                path="/contact"
                element={
                  <GenericPage
                    title={
                      translations.find(
                        (translation) => translation.id === "contact_page_title"
                      )?.[language]
                    }
                    description={
                      translations.find(
                        (translation) =>
                          translation.id === "contact_page_description"
                      )?.[language]
                    }
                  />
                }
              />
            </Routes>
          </div>
        </ConfigProvider>
      </div>
    </div>

    // </Router>
  );
}

export default App;
