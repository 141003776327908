import React, { useState, useEffect } from "react";

import "./AlbumsPage.css";

import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";

import { HomeOutlined, UserOutlined } from "@ant-design/icons";
import { Breadcrumb, Select } from "antd";
import { useSelector } from "react-redux";
import Album from "../../components/Albums/Album/Album";

import { Modal, Input, Button, Image, Upload } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import InfiniteGallery from "../../components/InfiniteGallery/InfiniteGallery";
import TextArea from "antd/es/input/TextArea";

function AlbumsPage() {
  const language = useSelector((state) => state.language.language);
  const direction = useSelector((state) => state.language.direction);
  const translations = JSON.parse(sessionStorage.getItem("translations"));
  const config = JSON.parse(sessionStorage.getItem("config"));

  const [albums, setAlbums] = useState([]);
  const [addAlbumModalVisible, setAddAlbumModalVisible] = useState(false);
  const [albumName, setAlbumName] = useState("");
  const [description, setDescription] = useState("");
  const [privacy, setPrivacy] = useState("public");

  let slug = (url) => new URL(url).pathname.match(/[^\/]+/g).pop();

  const getAllAlbumsApiEndpoint =
    process.env.REACT_APP_BACKEND_API_URL + "/api/getAllAlbums";

  const fetchData = () => {
    try {
      fetch(`${getAllAlbumsApiEndpoint}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      })
        .then((response) => response.json())
        .then((response_data) => {
          setAlbums(response_data);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  function handleAddAlbum() {
    setAddAlbumModalVisible(false);

    // Print the value of the input fields
    console.log("-------------\n| ADD ALBUM |\n-------------\n", "Name:\t\t\t" + albumName + "\n", "Description:\t" + description + "\n", "Privacy:\t\t" + privacy);
  }

  return (
    <div>
      <Header selectedLanguage={language} showSearch={true} showLinks={true} />
      <div
        style={{
          paddingTop: "80px",
          direction: direction,
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "0px 12px",
          }}
        >
          <Breadcrumb
            items={[
              {
                href: "/",
                title: (
                  <HomeOutlined
                    style={{
                      fontSize: "14px",
                    }}
                  />
                ),
              },
              {
                href: "/gallery",
                title: `${
                  translations.find(
                    (translation) => translation.id === "breadcrumb.gallery"
                  )?.[language]
                }`,
              },
              {
                className: "active_breadcrumb",
                title: `${
                  translations.find(
                    (translation) => translation.id === "sidebar.albums"
                  )?.[language]
                }`,
              },
            ]}
            style={{
              margin: "16px",
              marginBottom: "0px",
              fontSize: "18px",
              direction: direction,
            }}
          />
          <button
            className={"btn"}
            style={{
              backgroundColor: `${config.primaryColor}`,
            }}
            onClick={() => setAddAlbumModalVisible(true)}
          >
            {
              translations.find(
                (translation) => translation.id === "albums.add_album"
              )?.[language]
            }
          </button>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <Modal
            open={addAlbumModalVisible}
            onCancel={() => setAddAlbumModalVisible(false)}
            footer={[]}
            width={600}
            centered
            destroyOnClose={true}
            className="upload-modal"
            style={{
              direction: direction,
            }}
          >
            <div>
              <h2>
                {
                  translations.find(
                    (translation) => translation.id === "albums.add_album"
                  )?.[language]
                }
              </h2>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "20px",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    flexDirection: "column",
                    gap: "10px",
                    justifyContent: "center",
                    alignItems: "start",
                  }}
                >
                  <Input
                    name="album_name_input_field"
                    type="text"
                    placeholder={
                      translations.find(
                        (translation) =>
                          translation.id === "albums.album_name_input"
                      )?.[language]
                    }
                    onChange={(e) => setAlbumName(e.target.value)}
                  />
                  <TextArea
                    name="description_input_field"
                    type="text"
                    placeholder={
                      translations.find(
                        (translation) => translation.id === "albums.description"
                      )?.[language]
                    }
                    onChange={(e) => setDescription(e.target.value)}
                    rows={6}
                  />
                  <Select
                    defaultValue="public"
                    style={{
                      width: "100%",
                      border: "1px solid #d9d9d9",
                      borderRadius: "5px",
                    }}
                    name="privacy_input_field"
                    onChange={(value) => setPrivacy(value)}
                  >
                    <Select.Option value="public">
                      {
                        translations.find(
                          (translation) => translation.id === "albums.public"
                        )?.[language]
                      }
                    </Select.Option>
                    <Select.Option value="private">
                      {
                        translations.find(
                          (translation) => translation.id === "albums.private"
                        )?.[language]
                      }
                    </Select.Option>
                  </Select>
                </div>

                <button
                  className="btn"
                  style={{
                    backgroundColor: `${config.primaryColor}`,
                  }}
                  onClick={handleAddAlbum}
                >
                  {
                    translations.find(
                      (translation) => translation.id === "albums.add_album"
                    )?.[language]
                  }
                </button>
              </div>
            </div>
          </Modal>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              width: "100%",
            }}
          >
            <div className="albums-page">
              {albums.map((albums, index) => (
                <Album
                  key={`albums_${albums.uuid}`}
                  // title={
                  //   translations.find(
                  //     (translation) =>
                  //       translation.id === `albums.${albums.translation_id}`
                  //   )?.[language]
                  // }
                  id={albums.uuid}
                  album_name={albums.album_name}
                  translationsId={albums.translation_id}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </div>
  );
}

export default AlbumsPage;
