import React from "react";

import { useSelector } from "react-redux";

import { InboxOutlined, PlusOutlined, DownOutlined } from "@ant-design/icons";
import moment from "moment";

import {
  message,
  Upload,
  Input,
  DatePicker,
  Button,
  Space,
  Tag,
  Tooltip,
  theme,
  Checkbox,
  Progress,
  Dropdown,
  Select,
} from "antd";

import "./UploadScreen.css";

import { v4 as uuid } from "uuid";
import EXIF from "exif-js";

import config from "../../config/config";

const { Dragger } = Upload;

function UploadScreen() {
  const language = useSelector((state) => state.language.language);
  const translations = JSON.parse(sessionStorage.getItem("translations"));
  const config = JSON.parse(sessionStorage.getItem("config"));

  const [fileList, setFileList] = React.useState([]);

  const [totalFilesSize, setTotalFilesSize] = React.useState(1);
  const [totalFilesSizeUploaded, setTotalFilesSizeUploaded] = React.useState(0);

  const [sendButtonDisabled, setSendButtonDisabled] = React.useState(true);

  const [uploading, setUploading] = React.useState(false);

  const user = useSelector((state) => state.user);

  // Tags things
  const { token } = theme.useToken();
  const [editInputIndex, setEditInputIndex] = React.useState(-1);
  const [editInputValue, setEditInputValue] = React.useState("");
  const editInputRef = React.useRef(null);
  const [inputValue, setInputValue] = React.useState("");
  const [inputVisible, setInputVisible] = React.useState(false);
  const inputRef = React.useRef(null);

  const handleEditInputConfirm = () => {
    const newTags = [...tags];
    newTags[editInputIndex] = editInputValue;
    setTags(newTags);
    setEditInputIndex(-1);
    setInputValue("");
  };

  const handleClose = (removedTag) => {
    const newTags = tags.filter((tag) => tag !== removedTag);
    setTags(newTags);
  };

  React.useEffect(() => {
    if (inputVisible) {
      inputRef.current?.focus();
    }
  }, [inputVisible]);

  React.useEffect(() => {
    editInputRef.current?.focus();
  }, [inputValue]);

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleInputConfirm = () => {
    if (inputValue && tags.indexOf(inputValue) === -1) {
      setTags([...tags, inputValue]);
    }
    setInputVisible(false);
    setInputValue("");
  };

  const showInput = () => {
    setInputVisible(true);
  };
  // End tags things

  // Tree tags things
  const [items, setItems] = React.useState([]);

  const [editedCheckbox, setEditedCheckbox] = React.useState(false);

  React.useEffect(() => {
    // Fetch data from the API endpoint
    fetch(`${process.env.REACT_APP_BACKEND_API_URL}/api/getTagsTree`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setItems(data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  const onClick = function ({ key }) {
    // Add the selected tag to the selectedTreeTags state
    setTags((prevSelectedTags) => [...prevSelectedTags, key]);

    // Remove the selected tag from the items list
    const newItems = items.map((item) => {
      const updatedItem = { ...item };
      if (updatedItem.children) {
        updatedItem.children = updatedItem.children.filter(
          (child) => child.key !== key
        );
      }
      return updatedItem;
    });

    setItems(newItems); // Assuming you have a state variable named `items` to store the tags tree structure
  };
  // End tree tags things

  const [showDatePicker, setShowDatePicker] = React.useState(false);
  const [date, setDate] = React.useState("");
  const [tags, setTags] = React.useState([]);
  const [photographer, setPhotographer] = React.useState("");
  const [description, setDescription] = React.useState("");

  const handleCheckboxChange = (e) => {
    setShowDatePicker(e.target.checked);
  };

  const handleEditedCheckboxChange = (e) => {
    setEditedCheckbox(e.target.checked);
  };

  const handleEditedSelectChange = (value) => {
    console.log(`selected ${value}`);
    setEditedCheckbox(value);
  };

  async function uploadChunk(file, start, end, uploadId) {
    const type = file.type.split("/")[0].toLowerCase();
    const chunk = file.slice(start, end);

    const response = await fetch(
      `${process.env.REACT_APP_UPLOAD_API_URL}/upload?sessionId=${uploadId}&start=${start}&fileType=${type}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/octet-stream",
          "Access-Control-Allow-Origin": "*",
        },
        body: chunk,
      }
    );

    if (!response.ok) {
      console.log("Error uploading chunk --->", response);
    }
  }

  const props = {
    name: "file",
    multiple: true,
    fileList: fileList,
    // listType: "picture",
    // showUploadList: totalFilesSize > 0,
    showUploadList: {
      showPreviewIcon: false,
      showRemoveIcon: !uploading,
      showDownloadIcon: false,
    },
    disabled:
      totalFilesSizeUploaded > 0 && totalFilesSizeUploaded <= totalFilesSize,
    beforeUpload: () => {
      return false;
    },
    // action: 'https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188',
    onChange(info) {
      setSendButtonDisabled(false);
      const { status } = info.file;
      if (status !== "uploading") {
        setFileList(info.fileList);
      }
      if (status === "done") {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
      console.log("info.fileList", info.fileList);
    },
    onDrop(e) {
      setSendButtonDisabled(false);
    },
    style: {
      display: uploading ? "none" : "block",
    },
  };

  function generateUniqueFilename(originalFilename) {
    const name = originalFilename.substring(
      0,
      originalFilename.lastIndexOf(".")
    );
    const ext = originalFilename.substring(originalFilename.lastIndexOf("."));
    const timestamp = Math.floor(Date.now() / 1000);
    const randomId = uuid();
    return `${randomId}`;
  }

  async function uploadFile(file) {
    console.log("uploadFile file: ", file);
    const chunkSize = 10 * 1024 * 1024;
    const totalChunks = Math.ceil(file.size / chunkSize);
    const fileName = uuid();
    const kalPhotosUuid =
      generateUniqueFilename(file.name) + "." + file.name.split(".").pop();

    for (let i = 0; i < totalChunks; i++) {
      const start = i * chunkSize;
      const end = Math.min(file.size, start + chunkSize);

      await uploadChunk(
        file,
        start,
        end,
        kalPhotosUuid
        // fileName + "." + file.name.split(".").pop()
      );
      const uploadedChunkSize = end - start;

      setTotalFilesSizeUploaded(
        (prevTotalFilesSizeUploaded) =>
          prevTotalFilesSizeUploaded + uploadedChunkSize
      );

      // if all chunks are uploaded successfully remove the file from the list
      if (i === totalChunks - 1) {
        setFileList((prevFileList) => {
          const index = prevFileList.findIndex((item) => item.uid === file.uid);
          if (index > -1) {
            const newFileList = [...prevFileList];
            newFileList.splice(index, 1);
            return newFileList;
          }
          return prevFileList;
        });
      }
    }

    function getExifData(fileData) {
      return new Promise((resolve) => {
        EXIF.getData(fileData, function () {
          const tags = EXIF.getAllTags(this);
          resolve(tags);
        });
      });
    }

    const exifData = await getExifData(file);
    while (
      !exifData ||
      exifData === undefined ||
      exifData === null ||
      exifData === {}
    ) {
      console.log("No EXIF data");
      exifData = await getExifData(file);
    }
    const photoDate = exifData.DateTimeOriginal;
    console.log("photoDate: --------->", photoDate);

    // Convert date to timestamp
    // const photoDateToTimestamp = Math.floor(
    //   new Date(photoDate) / 1000
    // ).toString();

    const bucket =
      file.type.split("/")[0].toLowerCase() === "image"
        ? process.env.REACT_APP_PUBLIC_BUCKET_IMAGES
        : process.env.REACT_APP_PUBLIC_BUCKET_VIDEOS;

    const dateCheckbox = document.querySelector("#dynamicDateCheckbox");
    const photoDateFinal = dateCheckbox.checked
      ? Math.floor(new Date(date) / 1000).toString()
      : Math.floor(new Date() / 1000).toString();

    console.log("exifData", exifData);

    let orientation = "";

    if (exifData.PixelXDimension > exifData.PixelYDimension) {
      orientation = "landscape";
    } else if (exifData.PixelXDimension < exifData.PixelYDimension) {
      orientation = "portrait";
    } else {
      orientation = "square";
    }

    console.log("exifData.PixelXDimension ", exifData.PixelXDimension);
    console.log("exifData.PixelYDimension ", exifData.PixelYDimension);
    console.log("orientation: ", orientation);

    let metadata_json = {
      uuid: kalPhotosUuid,
      url: `${bucket}/${kalPhotosUuid}`,
      photoDate: photoDateFinal,
      photographer: photographer,
      tags: { tags: tags },
      description: description,
      fileName: file.name,
      type: file.type.split("/")[0].toLowerCase(),
      thumbnail: `${process.env.REACT_APP_PUBLIC_BUCKET_THUMBNAILS}/${
        kalPhotosUuid.split(".")[0]
      }.webp`,
      fileSize: file.size,
      orientation: orientation,
      aspectRatio: `${exifData.PixelXDimension}:${exifData.PixelYDimension}`,
      duration: exifData.Duration ? exifData.Duration : 0,
      // color: exifData.ColorSpace ? 'colourful' : 'blackAndWhite',
      color: "colourful",
      jwt: user.jwt,
      isEdited: editedCheckbox,
    };

    // if any key value is undefined, make it null
    Object.keys(metadata_json).forEach((key) => {
      if (metadata_json[key] === undefined) {
        metadata_json[key] = null;
      }
    });

    console.log("metadata_json: ", metadata_json);

    await fetch(
      process.env.REACT_APP_BACKEND_API_URL + "/api/postImageMetaData",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify(metadata_json),
      }
    );
    // await fetch(
    //   process.env.REACT_APP_BACKEND_API_URL + "/api/postImageMetaData",
    //   {
    //     method: "POST",
    //     headers: {
    //       "Content-Type": "application/json",
    //       "Access-Control-Allow-Origin": "*",
    //     },
    //     body: JSON.stringify({
    //       jwt: user.jwt,
    //       uuid: uploadId,
    //       url: `${bucket}/${fileName + "." + file.name.split(".").pop()}`,
    //       photoDate: 1999999999,
    //       photographer: photographer,
    //       tags: { tags: tags },
    //       description: description,
    //       fileName: file.name,
    //       type:
    //         file.type.split("/")[0].toLowerCase() === "image"
    //           ? "image"
    //           : "video",
    //       thumbnail: `${process.env.REACT_APP_PUBLIC_BUCKET_THUMBNAILS}/${fileName}.webp`,
    //       fileSize: file.size,
    //       // orientation: orientation,
    //       orientation:  `NEED_TO_ADD`,
    //       duration: 0,
    //       color: "colourful",
    //       // aspectRatio:  `${exifData.PixelXDimension}:${exifData.PixelYDimension}`,
    //       aspectRatio:  `NEED_TO_ADD`,
    //     }),
    //   }
    // );
  }

  // If trying to refresh while uploading show warning
  React.useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (uploading) {
        const message =
          "Leaving this page will cancel the upload. Are you sure?";
        event.returnValue = message;
        return message;
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [uploading]);

  // use effect for total files size
  React.useEffect(() => {
    // setUploading(false);

    if (user.roles.includes("photographer") && user.roles.length == 1) {
    } else {
      const modalRoot = document.querySelector(".ant-modal-centered");
      if (!modalRoot) {
        return;
      } else {
        const modalMask = document.querySelector(".ant-modal-mask");
        modalRoot.classList.remove("uploading");
        modalMask.style.backgroundColor = "rgba(0, 0, 0, 0.45)";
      }
    }

    if (
      uploading &&
      totalFilesSize > 0 &&
      totalFilesSizeUploaded >= totalFilesSize
    ) {
      message.success(
        translations.find(
          (translation) => translation.id === "upload.sucess_message"
        )?.[language]
      );

      setFileList([]);
      setTotalFilesSize(0);
      setTotalFilesSizeUploaded(0);
      setSendButtonDisabled(false);
      setUploading(false);

      // close modal
      // find ant-modal-close
      const antModalClose = document.querySelector(".ant-modal-close");
      if (!antModalClose) {
        return;
      }
      antModalClose.click();

      return;
    }

    let totalSize = 0;
    fileList.forEach((file) => {
      totalSize += file.size;
    });
    setTotalFilesSize(totalSize);

    if (fileList.length === 0) {
      setSendButtonDisabled(true);
      setTags([]);
      setDescription("");
      setPhotographer("");
      setDate("");
      setShowDatePicker(false);
    }
  }, [fileList]);

  React.useEffect(() => {
    const progress = document.querySelector(".ant-progress-text");
    if (progress) {
      progress.style.transform = "scaleX(-1)";
    }
  }, []);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "20px",
      }}>
      <Dragger
        {...props}
        accept="image/png, image/jpeg, image/jpg, image/webp, image/bmp, image/gif, video/mp4, video/quicktime, video/webm">
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">
          {
            translations.find(
              (translation) => translation.id === "upload.title"
            )?.[language]
          }
        </p>
        <p className="ant-upload-hint">
          {
            translations.find(
              (translation) => translation.id === "upload.dragger_text"
            )?.[language]
          }
        </p>
      </Dragger>

      {fileList.length > 0 && (
        <center>
          <p style={{ color: "black", fontWeight: "bold" }}>
            {
              translations.find(
                (translation) => translation.id === "upload.number_of_files"
              )?.[language]
            }{" "}
            {fileList.length}
          </p>
        </center>
      )}

      <Progress
        percent={
          totalFilesSize > 0
            ? Math.round((totalFilesSizeUploaded / totalFilesSize) * 100) <= 0
              ? 2
              : Math.round((totalFilesSizeUploaded / totalFilesSize) * 100)
            : 0
        }
        status={
          totalFilesSize > 0 && totalFilesSizeUploaded >= totalFilesSize
            ? "success"
            : "active"
        }
        style={{
          transform: "scaleX(-1)",
          display: uploading ? "block" : "none",
        }}
        strokeColor={config.primaryColor}
      />

      {/* Tags Tree */}
      {config.shouldDisplayTreeTags === true && (
        <div
          className="tags-tree-container"
          style={{
            color: `${config.textColor}`,
            display: !uploading ? "flex" : "none",
          }}>
          <Dropdown menu={{ items, onClick }}>
            <a onClick={(e) => e.preventDefault()}>
              <Space
                style={{
                  fontWeight: "semibold",
                  color: `${config.textColor}`,
                }}>
                {
                  translations.find(
                    (translation) => translation.id === "tree_tags"
                  )?.[language]
                }
                <DownOutlined
                  style={{
                    fontSize: "12px",
                    color: `${config.textColor}`,
                  }}
                />
              </Space>
            </a>
          </Dropdown>
        </div>
      )}

      <Space
        size={[0, 8]}
        wrap
        id="tags_container"
        style={{
          display: !uploading ? "flex" : "none",
        }}>
        {tags.map((tag, index) => {
          if (editInputIndex === index) {
            return (
              <Input
                ref={editInputRef}
                key={tag}
                size="small"
                style={{ width: "78", verticalAlign: "top" }}
                value={editInputValue}
                onChange={(e) => {
                  setEditInputValue(e.target.value);
                }}
                // onBlur={handleEditInputConfirm}
                onPressEnter={handleEditInputConfirm}
              />
            );
          }
          const isLongTag = tag.length > 20;
          const tagElem = (
            <Tag
              key={tag}
              closable
              style={{
                userSelect: "none",
              }}
              onClose={() => handleClose(tag)}
              className="antdtag">
              <span>{isLongTag ? `${tag.slice(0, 20)}...` : tag}</span>
            </Tag>
          );
          return isLongTag ? (
            <Tooltip title={tag} key={tag}>
              {tagElem}
            </Tooltip>
          ) : (
            tagElem
          );
        })}

        {/* Add new tag */}
        {inputVisible ? (
          <Input
            ref={inputRef}
            type="text"
            size="small"
            style={{
              width: "78",
              verticalAlign: "top",
              display: !uploading ? "flex" : "none",
            }}
            value={inputValue}
            onChange={handleInputChange}
            onBlur={handleInputConfirm}
            onPressEnter={handleInputConfirm}
            maxLength={100}
          />
        ) : (
          <Tag
            style={{
              background: token.colorBgContainer,
              borderStyle: "dashed",
              padding: "1px 6px",
              fontSize: "14px",
              color: "rgba(0, 0, 0, 0.45)",
              fontFamily: "Rubik",
              letterSpacing: "1px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              display: !uploading ? "flex" : "none",
            }}
            onClick={showInput}
            className="tags">
            <PlusOutlined
              style={{
                fontSize: "10px",
                fontFamily: "Rubik",
                fontWeight: "bold",
                color: "rgba(0, 0, 0, 0.45)",
                marginLeft: "4px",
              }}
            />{" "}
            {
              translations.find(
                (translation) => translation.id === "upload.add_tag"
              )?.[language]
            }
          </Tag>
        )}
      </Space>

      <Input
        rows={4}
        placeholder={
          translations.find(
            (translation) => translation.id === "upload.description"
          )?.[language]
        }
        style={{
          display: !uploading ? "flex" : "none",
        }}
        onChange={(e) => setDescription(e.target.value)}
        value={description}
      />

      <Input
        placeholder={
          translations.find(
            (translation) => translation.id === "upload.photographer"
          )?.[language]
        }
        style={{
          display: !uploading ? "flex" : "none",
        }}
        onChange={(e) => setPhotographer(e.target.value)}
        value={photographer}
      />

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          gap: "6px",
        }}>
        {/* {config.shouldDisplayEditedCheckbox && (
          <Checkbox
            id="editedCheckbox"
            onChange={handleEditedCheckboxChange}
            style={{
              display: !uploading ? "flex" : "none",
            }}
          >
            {
              translations.find(
                (translation) => translation.id === "upload.edited"
              )?.[language]
            }
          </Checkbox>
        )} */}
        {config.shouldDisplayEditedCheckbox && (
          <Select
            id="editedSelect"
            onChange={handleEditedSelectChange}
            style={{
              display: !uploading ? "flex" : "none",
              minWidth: "30%",
            }}
            placeholder={
              translations.find(
                (translation) => translation.id === "upload.edited"
              )?.[language]
            }>
            <Select.Option value={0}>
              {
                translations.find(
                  (translation) => translation.id === "upload.edited"
                )?.[language]
              }
            </Select.Option>

            <Select.Option value={1}>
              {
                translations.find(
                  (translation) => translation.id === "upload.edited_2"
                )?.[language]
              }
            </Select.Option>

            <Select.Option value={2}>
              {
                translations.find(
                  (translation) => translation.id === "upload.edited_3"
                )?.[language]
              }
            </Select.Option>
          </Select>
        )}

        <Checkbox
          id="dynamicDateCheckbox"
          onChange={handleCheckboxChange}
          checked={showDatePicker}
          style={{
            display: !uploading ? "flex" : "none",
            marginInlineStart: "0px",
          }}>
          {
            translations.find(
              (translation) => translation.id === "upload.photo_date"
            )?.[language]
          }
        </Checkbox>
      </div>

      {showDatePicker && (
        <DatePicker
          id="dynamicDate"
          placeholder={
            translations.find(
              (translation) => translation.id === "upload.photo_date"
            )?.[language]
          }
          style={{
            width: "100%",
            marginBottom: "24px",
            display: !uploading ? "flex" : "none",
          }}
          className="date"
          onChange={(date, dateString) => setDate(dateString)}
          disabledDate={(current) => current && current > moment().endOf("day")}
        />
      )}

      <div style={{ textAlign: "center" }}>
        <Button
          className="upload-button-send"
          key="submit"
          type="primary"
          disabled={
            (totalFilesSizeUploaded > 0 &&
              totalFilesSizeUploaded <= totalFilesSize) ||
            sendButtonDisabled
          }
          onClick={() => {
            message.loading(
              translations.find(
                (translation) => translation.id === "upload.started"
              )?.[language]
            );

            setUploading(true);

            if (user.roles.includes("photographer") && user.roles.length == 1) {
            } else {
              const modalRoot = document.querySelector(".ant-modal-centered");
              if (!modalRoot) {
                return;
              } else {
                const modalMask = document.querySelector(".ant-modal-mask");
                modalRoot.classList.add("uploading");
                modalMask.style.backgroundColor = "rgba(0, 0, 0, 0)";
              }
            }

            setSendButtonDisabled(true);

            // calculate total files size
            let totalSize = 0;
            fileList.forEach((file) => {
              totalSize += file.size;
            });
            // setTotalFilesSize(totalSize);

            // upload files
            fileList.forEach((file) => {
              uploadFile(file.originFileObj);
            });
          }}
          style={{
            marginTop: "20px",
            display: !uploading ? "inline-block" : "none",
            // display:
            //   fileList.length > 0 && !uploading ? "inline-block" : "none",
            backgroundColor: sendButtonDisabled ? "" : config.primaryColor,
          }}>
          {
            translations.find(
              (translation) => translation.id === "upload.send"
            )?.[language]
          }
        </Button>
      </div>
    </div>
  );
}
export default UploadScreen;
