import { createSlice } from "@reduxjs/toolkit";

const imagesSlice = createSlice({
  name: "images",
  initialState: {
    images: [],
  },
  reducers: {
    setImages: (state, action) => {
      state.images = action.payload;
    },
    updateImage: (state, action) => {
      const newImage = action.payload;
      const index = state.images.findIndex(
        (image) => image.uuid === newImage.uuid
      );
      if (index !== -1) {
        state.images[index] = newImage;
      }
    },
    deleteImage: (state, action) => {
      const uuid = action.payload;
      state.images = state.images.filter((image) => image.uuid !== uuid);
    }
  },
});

export const { setImages, updateImage } = imagesSlice.actions;

export default imagesSlice.reducer;
